import React from 'react';
import Header from "../header";
import Footer from "../footer";

const Branch = () => {
  return (
    <>
      <Header currentMenu={2} />
      <div className='title' >
        <span className='title-text'>사업분야</span>
      </div>
      <div className="wrap">
        <div className='branch'></div>
      </div>
    </>
  );
};

export default Branch;