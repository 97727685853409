import React from 'react';
import Header from "../header";
import Footer from "../footer";

const Map = () => {
  return (
    <>
      <Header currentMenu={4} />
      <div className='title'>
        <span className='title-text'>
          오시는 길
        </span>
      </div>
      <div className="wrap">
        <div className='map' />
      </div>
    </>
  );
};

export default Map;