import React, {useState} from 'react';
import Header from "../header";
import Footer from "../footer";

const Hire = () => {
  const [foldFront, setFoldFront] = useState(true);
  const [foldBack, setFoldBack] = useState(true);

  const handleClickFoldFront = () => {
    setFoldFront(!foldFront);
  };
  const handleClickFoldBack = () => {
    setFoldBack(!foldBack);
  };

  return (
    <>
      <Header currentMenu={3} />
      <div className='title' >
        <span className='title-text'>인재채용</span>
      </div>
      <div className="wrap">
        <div className='hire' />

        <div className={`hire-front-title ${foldFront ? 'fold' : ''}`} onClick={handleClickFoldFront} />
        <div className='hire-front-content' style={{display: foldFront ? 'none' : ''}} />
        <div className={`hire-back-title ${foldBack ? 'fold' : ''}`} onClick={handleClickFoldBack} />
        <div className='hire-back-content' style={{display: foldBack ? 'none' : ''}} />
        <div className={'bottom-margin'} />
      </div>
    </>
  );
};

export default Hire;