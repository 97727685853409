import React from 'react';
import Header from "../header";
import Footer from "../footer";

const Introduce = () => {
  return (
    <>
      <Header currentMenu={1}/>
      <div className='title' >
        <span className='title-text'>회사소개</span>
      </div>
      <div className="wrap">
        <div className='introduce' />
      </div>
    </>
  );
};

export default Introduce;