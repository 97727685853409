import React from 'react';

const Header = ({currentMenu}) => {
  const handleClickMenu = menu => {
    window.location.href = `/${menu}`;
  };
  return (
    <div className='header'>
      <div id='header-logo' onClick={() => {handleClickMenu('')}} />
      <ul className='menu-bar'>
        <li id='menu1' className={currentMenu === 1 ? 'active' : ''} onClick={() => {handleClickMenu('message')}} />
        <li id='menu2' className={currentMenu === 2 ? 'active' : ''} onClick={() => {handleClickMenu('service')}} />
        <li id='menu3' className={currentMenu === 3 ? 'active' : ''} onClick={() => {handleClickMenu('recruit')}} />
        <li id='menu4' className={currentMenu === 4 ? 'active' : ''} onClick={() => {handleClickMenu('map')}} />
      </ul>
    </div>
  );
};

export default Header;