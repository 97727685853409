import React from 'react';
import Slider from "react-slick";
import Header from "../header";
import Footer from "../footer";

const Home = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  const handleClickMore = () => {
    window.location.href = '/recruit';
  };
  return (
    <div className="wrap">
      <Header />
      <div className='more-button' onClick={handleClickMore}/>

      <Slider {...sliderSettings}>
        <div className="main1" />
        <div className="main2" />
        <div className="main3" />
      </Slider>

      <div className='home' />

      <div className="partner_wrap">
        <div className={`partner_list`}>
          <div className="partner_content">
            <div className='partner1' />
          </div>
          <div className="partner_content">
            <div className='partner2' />
          </div>
        </div>
      </div>

      <div className='bottom-margin' />
      {/*<Footer />*/}
    </div>
  );
};

export default Home;