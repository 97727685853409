import React, {Component} from "react"
import {BrowserRouter, Route, Switch} from 'react-router-dom'
/* CSS */
import './assets/css/common.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Home from './components/home';
import Introduce from './components/introduce';
import Branch from './components/branch';
import Hire from './components/hire';
import Map from './components/map';
import Footer from "./components/footer";

class Root extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="wrapper">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/message" component={Introduce} authenticated="false"/>
            <Route path="/service" component={Branch} authenticated="false"/>
            <Route path="/recruit" component={Hire} authenticated="false"/>
            <Route path="/map" component={Map} authenticated="false"/>
          </Switch>
        </BrowserRouter>
        <div className='footer-wrapper' >
          <Footer />
        </div>
      </div>
    )
  }
}

export default Root;
